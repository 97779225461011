import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Autoplay, Navigation, EffectFade } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { useIntl } from "gatsby-plugin-react-intl"

const OverviewBanner = () => {
  const intl = useIntl()
  const { bannerSlides } = useStaticQuery(graphql`
    query {
      bannerSlides: allFile(
        filter: {
          relativeDirectory: { eq: "overview" }
          name: { regex: "/-slide-/" }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          name
          childImageSharp {
            gatsbyImageData(
              height: 510
              placeholder: BLURRED
              quality: 90
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `)

  return (
    <div id="hero-banner" className="bg-white">
      <Swiper
        modules={[Navigation, Autoplay, EffectFade]}
        slidesPerView={1}
        loop
        autoplay={{
          delay: 9000,
        }}
        navigation
        effect="fade"
      >
        {bannerSlides?.nodes.map((bannerSlide, index) => {
          return (
            <SwiperSlide
              className="relative flex justify-center items-center"
              key={bannerSlide?.id}
            >
              <GatsbyImage
                image={bannerSlide?.childImageSharp?.gatsbyImageData}
                alt="hero banner images"
                className="min-w-full min-h-[18rem] lg:min-h-[20rem] bg-image-darken"
              />
              <h2 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-6xl font-PTSerif font-bold italic text-center">
                {intl.formatMessage({ id: "Firm Overview" })}
              </h2>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default OverviewBanner
