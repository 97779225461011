import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import ExpertsSection from "../../components/ExpertsSection"
import WhySection from "../../components/WhySection"
import OverviewBanner from "../../components/OverviewBanner"
import { useIntl } from "gatsby-plugin-react-intl"

const FirmPage = ({ data: { page, bgImages } }) => {
  const intl = useIntl()
  return (
    <Layout>
      <h1 className="hidden">{page?.title}</h1>
      <Seo seoData={page?.seo} />
      <OverviewBanner />
      <ExpertsSection />
      <div className="relative flex justify-center items-center">
        <GatsbyImage
          image={bgImages?.nodes[0].childImageSharp?.gatsbyImageData}
          alt="Workers’ Compensation Attorneys in New York"
          className="min-w-full min-h-[32rem] bg-image-darken"
        />
        <div className="w-10/12 mx-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-white">
          <h3 className="font-PTSerif font-bold text-5xl pb-8">
            {intl.formatMessage({ id: "firmOverview1" })}
          </h3>
          <p className="pb-5 lg:w-8/12 mx-auto">
            {intl.formatMessage({ id: "firmOverview2" })}
          </p>
          <p className="pb-8 lg:w-8/12 mx-auto">
            {intl.formatMessage({ id: "firmOverview3" })}
          </p>
        </div>
      </div>
      <div className="w-10/12 mx-auto py-10">
        <GatsbyImage
          image={bgImages?.nodes[1].childImageSharp?.gatsbyImageData}
          alt="Pomares Law Group Attorneys New York"
          className="float-left mr-5 max-w-[600px]"
        />
        <div>
          <h3 className="font-PTSerif font-bold text-2xl pb-3">
            {intl.formatMessage({ id: "firmOverview4" })}
          </h3>
          <p className="pb-3">{intl.formatMessage({ id: "firmOverview5" })}</p>
          <h3 className="font-PTSerif font-bold text-2xl pb-3">
            {intl.formatMessage({ id: "firmOverview6" })}
          </h3>
          <p className="pb-3">{intl.formatMessage({ id: "firmOverview7" })}</p>
          <h3 className="font-PTSerif font-bold text-2xl pb-3">
            {intl.formatMessage({ id: "firmOverview8" })}
          </h3>
          <p className="pb-3">{intl.formatMessage({ id: "firmOverview9" })}</p>
          <p className="pb-3">{intl.formatMessage({ id: "firmOverview10" })}</p>
          <p className="pb-3">{intl.formatMessage({ id: "firmOverview11" })}</p>
        </div>
      </div>
      <WhySection />
    </Layout>
  )
}

export default FirmPage

export const pageQuery = graphql`
  query FirmPageById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      slug
      seo {
        fullHead
        schema {
          raw
        }
      }
    }
    bgImages: allFile(
      filter: {
        relativeDirectory: { eq: "overview" }
        name: { regex: "/-bg-/" }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        name
        childImageSharp {
          gatsbyImageData(
            height: 450
            placeholder: BLURRED
            quality: 90
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
`
